<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên bài viết </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider
            name="name"
            rules="required"
            v-slot="{ errors }"
        >
          <hnr-input
              :text="detailLesson.name"
              @input="updateName"
              v-model="detailLesson.name"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailLesson.slug"
            v-model="detailLesson.slug"
            @input="changeSlug"
        ></hnr-input>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2 mb-2">
      <v-col cols="2" class="pa-1">
        <span> Chủ đề cha </span>
      </v-col>
      <v-col cols="8" class="pa-1">
          <span>
            {{ detailLesson.topics ? detailLesson.topics.name : "" }}
          </span>
      </v-col>
      <v-col cols="2" class="pa-1">
        <v-btn color="primary" @click="updateParent"
        >Cập nhật chủ đề cha
        </v-btn
        >
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Nội dung </span>
      </v-col>
      <v-col cols="12" class="pa-1">
        <ValidationProvider
            name="content"
            rules="required"
            v-slot="{ errors }"
        >
          <ck-content
              id="lesson_content"
              :content="detailLesson.content"
              :slug="slug"
              @getData="updateContent"
              v-model="detailLesson.content"
          ></ck-content>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>

      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Kích hoạt </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-switch v-model="detailLesson.status"></v-switch>
      </v-col>
    </v-row>


    <!--    <v-row v-if="!isNew" class="align-center justify-center">-->
    <!--      <v-col cols="2" class="pa-1">-->
    <!--        <span> Số lượt xem </span>-->
    <!--      </v-col>-->
    <!--      <v-col cols="10" class="pa-1">-->
    <!--        <span> {{ detailLesson.view }}</span>-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Đăng bởi </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <span v-if="detailLesson.publish_by">{{ detailLesson.publish_by }}</span>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="800px" min-height="600px">
      <v-card>
        <v-card-title class="text-center">
          <span class="headline">Thay đổi chủ đề cha</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="align-start justify-center ma-0">
              <v-col cols="8">
                <!-- <v-autocomplete
                v-model="value"
                :items="suggestNewCategories"
                item-value='id'
                item-text='name'
                label="Chọn chủ đề cho bài viết"
                :search-input.sync="search"
              >
              </v-autocomplete> -->
                <hnr-input :text="search" v-model="search"></hnr-input>
              </v-col>
              <v-col cols="4">
                <v-btn color="primary" @click="searchCategoriesByName"
                >Tìm kiếm
                </v-btn
                >
              </v-col>

            </v-row>
            <!--              <v-row>-->
            <!--                <v-col cols="2" class="pa-0 ma-0 ml-5">-->
            <!--                  <v-btn color="primary" @click="setRootParent"-->
            <!--                  >Đặt làm chủ đề gốc</v-btn-->
            <!--                  >-->
            <!--                </v-col>-->
            <!--              </v-row>-->
            <v-row class="align-start justify-center ma-0 ">
              <v-col cols="12">
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="suggestNewCategories"
                    :single-select="singleSelect"
                    item-key="id"
                    show-select
                    class="elevation-1"
                >
                  <template v-slot:item.grade="{ item }">
                    {{ item.grade?.name }}
                  </template>
                  <template v-slot:item.subject="{ item }">
                    {{ item.subject?.name }}
                  </template>
                </v-data-table>
              </v-col>


            </v-row>
            <v-row class="align-center justify-center ma-0 pt-5">
              <v-btn color="primary" @click="saveNewsCategory"
              >Lưu chủ đề cha
              </v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Ngày tạo </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-date-picker
          @getPicker="getPicker"
          :date="detailLesson.created_at"
        />
      </v-col>
    </v-row>
        <hnr-input :text="detailLesson.create_time" :readonly="true" v-model="detailLesson.create_time"></hnr-input> -->

    <!-- <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Ngày sửa </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
          :text="detailLesson.update_time"
          :readonly="true"
          v-model="detailLesson.update_time"
        ></hnr-input>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import HnrAreaInput from "@/components/elements/hnr-area-input";
// import HnrDatePicker from "@/components/elements/hnr-date-picker";
import CkContent from "@/components/ck-content";
import {SET_LOADING} from "@/store/loading.module";
import {SEARCH_TOPIC_BY_NAME} from "@/store/topic.module";

import {mapState} from "vuex";
import mixin from "@/store/mixin";

export default {
  mixins: [mixin],
  data() {
    return {
      dialog: false,
      detailLesson: {},
      selected: [],
      value: 0,
      search: null,
      singleSelect: true,
      suggestNewCategories: [],
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          width: '10%'
        },
        {
          text: "Name",
          value: "name",
          width: '50%'
        },
        {
          text: "Lớp ",
          value: "grade",
        },
        {
          text: "Môn",
          value: "subject",
        },
      ],
      slug: this.$route.params.slug
    };
  },
  props: {
    lesson: Object,
    isNew: {type: Boolean, default: false},
    slug_message: {type: String},
  },
  computed: {
    ...mapState({
      nameOfNewsCategories: (state) => state.newscategory.nameOfNewscategories,
    }),

  },
  watch: {
    lesson: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailLesson = newVal;
      },
    },
    detailLesson: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (this.isNew) {
          // console.log(newVal)
          this.detailLesson.slug = this.sanitizeTitle(newVal.name);
        }
      },
    },
    nameOfNewsCategories: {
      deep: true,
      handler(newVal) {
        this.suggestNewCategories = newVal;
      },
    },
  },
  components: {
    HnrInput,
    HnrAreaInput,
    CkContent,
    // HnrDatePicker,
  },
  methods: {
    changeSlug() {
      this.detailLesson.slugHasChanged = true;
    },
    searchCategoriesByName() {
      if (this.search) {
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(SEARCH_TOPIC_BY_NAME, {name: this.search})
            .then(() => {
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(SET_LOADING, false);
            });
      }
    },
    saveNewsCategory() {
      if (this.selected && this.selected.length > 0) {
        this.detailLesson.topics = {
          id: this.selected[0].id,
          name: this.selected[0].name,
        };
      } else {
        this.detailLesson.topics = {
          id: 0,
          name: null,
        };
      }
      this.dialog = false;
    },
    getPicker(val) {
      this.detailLesson.created_at = val
    },
    updateName() {
      //generate slug
      this.$emit("input");
    },
    updateContent(val) {
      this.detailLesson.content = val;
    },
    updateParent() {
      this.dialog = true;
    },
    update() {
      this.$emit("input");
    },
    isRealValue(obj) {
      return obj && obj !== 'null' && obj !== 'undefined';
    }
    ,
    convertNumberToString(number) {
      if (this.isRealValue(number)) {
        return number.toString();
      }
      return "0";
    }
  }
  ,
}
;
</script>

<style lang="scss">
</style>
