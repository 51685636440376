<template>
  <v-container>
    <ValidationObserver v-slot="{ handleSubmit }">
    <v-tabs v-model="tabIndex">
      <v-tab v-for="item in items" :key="item.id" :disabled="isCreate && item.id == 2 ? true : false">{{ item.tab }}</v-tab>
    </v-tabs>
    <v-tabs-items  class="tab-content " v-model="tabIndex">
      <v-tab-item active>
        <t-lesson-content class="tab-detail" :lesson="detailLesson" :isNew="isCreate"></t-lesson-content>
      </v-tab-item>
      <v-tab-item>
        <t-lesson-seo class="tab-detail" :lesson="detailLesson"></t-lesson-seo>
      </v-tab-item>
       <v-tab-item>
         <hnr-edit-table class="tab-detail" :content="categories"
          @addRow="addRow"
          @updateData="updateItemTable"
          @deleteItem="deleteItem"></hnr-edit-table>
      </v-tab-item>
    </v-tabs-items>
    <v-row v-if="isCreate" class="align-center justify-center">
      <v-btn color="primary" @click="handleSubmit(saveLesson)">Lưu bài viết</v-btn>
    </v-row>
    <v-row v-else class="align-center justify-center">
      <v-btn color="primary" @click="handleSubmit(updateLesson)">Cập nhật bài viết</v-btn>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="600px"
      min-height="600px"
    >
      <v-card>
        <v-card-title class="text-center">
          <span class="headline">Thêm chủ đề cho bài viết</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="align-start justify-center ma-0">
              <v-col cols="8">
                <hnr-input :text="search" v-model="search"></hnr-input>
              </v-col>
              <v-col cols="4">
                <v-btn color="primary" @click="searchCategoriesByName">Tìm kiếm</v-btn>
              </v-col>
            </v-row>
            <v-row class="align-start justify-center ma-0">
              <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="suggestNewCategories"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
                >
                </v-data-table>
            </v-row>
            <v-row class="align-center justify-center ma-0 pt-5">
              <v-btn color="primary" @click="handleSubmit(saveNewsCategory)">Lưu chủ đề của bài viết</v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    </ValidationObserver>
  </v-container>
</template>
<script>
import { mapState } from "vuex";

// stores
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { SET_LOADING } from "@/store/loading.module";
import { SAVE_LESSON, UPDATE_LESSON, DETAIL_LESSON, ADD_CATEGORY_LESSON, DELETE_CATEGORY_LESSON } from "@/store/lesson.module";
import { SEARCH_NEWS_CATEGORY_BY_NAME } from "@/store/newscategory.module";
import { SUCCESS, ERROR } from "@/store/alert.module";
// components
import TLessonContent from "@/components/lesson/TLessonContent";
import TLessonSeo from "@/components/lesson/TLessonSeo";
import HnrInput from '@/components/elements/hnr-input'
import HnrEditTable from '@/components/elements/hnr-edit-table'

export default {
  data() {
    return {
      detailLesson: {
        id: '',
        name: "",
        slug: "",
        short_content: "",
        content: "",
        summary: "",
        act: "",
        order: "",
        seo_title: "",
        seo_description: "",
        seo_key: ""
      },
      slug: null,
      isCreate: false,

      isRedirect:true,
      tabIndex: 0,
      items: [
        { id: 0, tab: "Thông tin" },
        { id: 1, tab: "Cấu hình SEO" },
      ],
      categories: [],
      dialog: false,

      suggestNewCategories: [],
      // selectedCategories: [],
      value: 0,
      search: null,
      singleSelect: true,
      selected: [],
      headers: [
        {
          text: 'id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name' }
      ],

    };
  },
  components: {
    HnrInput,
    TLessonContent,
    TLessonSeo,
    HnrEditTable
  },
  computed: {
    ...mapState({
      lesson_state: (state) => state.lesson.detailLesson,
      nameOfNewsCategories: (state) => state.newscategory.nameOfNewscategories
    }),
    lesson:function (){
      let data= Object.assign({},this.lesson_state);
      // data.content= this.regexImageUrl(data.content);
      return data;
    }
  },
  watch: {
    lesson: {
      deep: true,
      handler(newVal) {
        this.detailLesson = newVal;
        this.getListOfNewsCategory(this.detailLesson.news_news_category);
      },
    },
    nameOfNewsCategories: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.length > 20) {
          this.suggestNewCategories = newVal.slice(0, 30);
        }
        else {
          this.suggestNewCategories = newVal;
        }
      },
    }
  },
  created() {
    this.slug = this.$route.params.slug;
    if (this.slug) {
      this.isCreate = false;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: this.slug }]);
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(DETAIL_LESSON, { slug: this.slug }).then(() => {
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
    }
    else {
      this.isCreate = true;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Thêm bài viết" }]);
    }
  },
  methods: {
    // regexImageUrl(content) {
    //   if (!content) {
    //     return null;
    //   }
    //   content = content.replace(
    //       /<img([\w\W]+?)>/gi,
    //       function (match) {
    //         match = match.replace(
    //             /src\s*=\s*"/g,
    //             function () {
    //               return 'src="' + process.env.VUE_APP_BASE_URL + "/";
    //             }
    //         );
    //         return match;
    //       }
    //   );
    //   return content;
    // },
    searchCategoriesByName() {
      if (this.search) {
        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(SEARCH_NEWS_CATEGORY_BY_NAME, {name: this.search}).then(() => {
          this.$store.dispatch(SET_LOADING, false);
        }).catch(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },
    saveLesson() {
      // if(this.detailLesson.order ==="" ){
      //   this.detailLesson.order = 0
      // }
      this.isRedirect = false;

      let payload = {
        name: this.detailLesson.name,
        slug: this.detailLesson.slug,
        parent_id: this.detailLesson.topics
            ? this.detailLesson.topics.id
            : null,
        content:  this.detailLesson.content,
        status: this.detailLesson.status ? 1 : 0,
        seo_title: this.detailLesson.seo_title,
        seo_description: this.detailLesson.seo_description,
        seo_key: this.detailLesson.seo_key,
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(SAVE_LESSON, payload).then(data => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Thêm bài viết thành công!", { root: true });
          this.$router.push('/lesson');
        }
        else {
          this.$store.dispatch(ERROR, data.message, { root: true });
        }
        this.$store.dispatch(SET_LOADING, false);
      }).catch((err) => {
        if(err.status == 422 && err.data.errors?.seo_title){
          this.$store.dispatch(ERROR, "Chưa điền SEO !", { root: true });
        }else{
          this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", {root: true});
        }
        this.$store.dispatch(SET_LOADING, false);
      });
    },
    updateLesson() {
      this.isRedirect = false;
      let payload = {
        id: this.detailLesson.id,
        content :{
          name: this.detailLesson.name,
          slug: this.detailLesson.slug,
          parent_id: this.detailLesson.topics
              ? this.detailLesson.topics.id
              : null,
          content: this.detailLesson.content,
          status: this.detailLesson.status ? 1 : 0,
          seo_title: this.detailLesson.seo_title,
          seo_description: this.detailLesson.seo_description,
          seo_key: this.detailLesson.seo_key,
          has_change_slug : this.detailLesson.slugHasChanged
        }

      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(UPDATE_LESSON, payload).then(data => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Cập nhật bài viết thành công!", { root: true });
          this.$router.push('/lesson');
        }
        else {
          if(data.errorCode == 424){
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }else {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }
        }
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", { root: true });
        this.$store.dispatch(SET_LOADING, false);
      });
    },
    updateImageUrl(content){
      if(!content){
        return null;
      }
      content = content.replace(
          /<img([\w\W]+?)>/gi,
          function (match) {
            match = match.replace(
                /src="([^"]+)"/g,
                function (match_child) {
                  let base_url= process.env.VUE_APP_BASE_URL
                  var regex = new RegExp(base_url+"/(.*)", "");
                  let relative_path = "";
                  let relative_path_arr =match_child.match(regex)
                  if(relative_path_arr && relative_path_arr.length>0){
                    relative_path=relative_path_arr[1];
                    return 'src="'+relative_path;
                  }
                }
            );
            return match;
          }
      );
      return content;
    },

    addRow() {
      this.dialog = true;
    },
    updateItemTable(payload) {
      var index = this.categories.findIndex(item => item.id == payload.id);
      this.categories[index] = payload;
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        let payload = { news_id: this.detailLesson.id, news_category_id: item.id };

        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(DELETE_CATEGORY_LESSON, payload).then(data => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Xóa chủ đề thành công!", { root: true });
          }
          else {
            this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", { root: true });
          }
          this.$store.dispatch(SET_LOADING, false);
        }).catch(() => {
          this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", { root: true });
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },

    getListOfNewsCategory(news2Categories) {
      let newCategroies = [];
      if (news2Categories) {
        news2Categories.forEach(element => {
            let category = {};
            category.id = element.category_id;
            category.name = element.news_category.name;
            category.class = element.news_category.procategory.name;
            newCategroies.push(category);
        });
      }
        this.categories = newCategroies;
    },
    saveNewsCategory() {
      if (this.selected && this.selected.length > 0) {
        this.value = this.selected[0].id;
      }
      if (this.value) {
        let payload = { news_id: this.detailLesson.id, news_category_id: this.value };
        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(ADD_CATEGORY_LESSON, payload).then(data => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Thêm chủ đề thành công!", { root: true });
          }
          else {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }
          this.$store.dispatch(SET_LOADING, false);
          this.dialog = false;
        }).catch(() => {
          this.$store.dispatch(ERROR, "Thêm chủ đề thất bại!", { root: true });
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },

  },
  beforeRouteLeave (to, from, next) {
    if(this.isRedirect){
      if (confirm("Bạn chưa lưu bài viết.Bạn vẫn muốn rời đi chứ?")) {
        next()
      }else{
        next(false)
      }
    }else{
      next();
    }
  },
};
</script>


<style lang="scss">
  .tab-detail{
    margin-top:15px;
  }
</style>
